export class QrCode extends HTMLInputElement {
  connectedCallback () {
    this.style.display = 'none'
    const innerHTML = `
            ${this.buildStyle()}
            <div class="qr-code">
                <img width="250" height="auto" src="${
                  this.dataset.qrcode
                }" alt="">
                <div class="qr-tooltip">
                    <p>Vous ne pouvez pas scanner le code ?</p>
                    <tool-tip>
                        <p>Vous pouvez ajouter manuellement le code à votre application de vérification en utilisant les informations suivantes : <br> <br> ${
                          this.dataset.content
                        }</p>
                    </tool-tip>
                </div>
            </div>
        `
    this.insertAdjacentHTML('afterend', innerHTML)
  }

  buildStyle () {
    return `
            <style>
                .qr-code {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .qr-tooltip {
                    display: flex;
                    flex-direction: row;
                                        justify-content: center;
                }
                .qr-tooltip tool-tip {
                    margin-left: 1rem;
                }
            </style>
        `
  }
}
