import '../../../core/components/index.js'

import { FormFlow } from '../../../core/components/FormFlow'
import { CitySearchInput } from '../../../core/components/CitySearchInput'
import { LiveChat } from '../../../core/components/LiveChat'
import {
  AlertMessage,
  FloatingAlertMessage
} from '../../../core/components/AlertMessage'
import { AjaxForm } from '../../../core/components/AjaxForm'
import { QrCode } from '../../../core/components/QrCode'
import { Tooltip } from '../../../core/components/Tooltip'
import { ConfirmModal } from '../../../core/components/ConfirmModal'

customElements.define('alert-message', AlertMessage)
customElements.define('ajax-form', AjaxForm, { extends: 'form' })
customElements.define('form-flow', FormFlow, { extends: 'form' })
customElements.define('city-search', CitySearchInput, { extends: 'select' })
customElements.define('live-chat', LiveChat)
customElements.define('qr-code', QrCode, { extends: 'input' })
customElements.define('tool-tip', Tooltip)
customElements.define('floating-alert-message', FloatingAlertMessage)
customElements.define('confirm-modal', ConfirmModal)
